import { cn, cva, type VariantProps } from "@nephroflow/design-system/styling/utils";
import * as React from "react";

import { RequireSome } from "~/shared/utils";

const headingVariants = cva("font-medium", {
  variants: {
    level: {
      1: "text-4xl leading-8",
      2: "text-3xl leading-6",
      3: "text-2xl leading-5",
      4: "text-xl leading-4",
      5: "text-lg leading-3",
    },
  },
});

export const Heading = React.forwardRef<
  HTMLHeadingElement,
  React.ButtonHTMLAttributes<HTMLHeadingElement> & RequireSome<VariantProps<typeof headingVariants>, "level">
>(({ className, level, ...props }, ref) => {
  const Comp = `h${level}` as "h1";
  return <Comp className={cn(headingVariants({ level }), className)} ref={ref} {...props} />;
});
Heading.displayName = "Heading";
